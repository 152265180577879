var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Stack, Text } from '@gameficame/ui-kit/src/components';
import { useAuth } from '~/layouts/hooks';
import * as Styled from './styles';
const Sidebar = (_a) => {
    var { isOpen, onClose, navItemActive, onClickPanel, onClickCampaign, onClickHistory, onClickPrizes, onClickMyProfile, onClickRaffle, onClickStatement } = _a, rest = __rest(_a, ["isOpen", "onClose", "navItemActive", "onClickPanel", "onClickCampaign", "onClickHistory", "onClickPrizes", "onClickMyProfile", "onClickRaffle", "onClickStatement"]);
    const { t } = useTranslation();
    const { logout } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        document.body.style.overflowY = isOpen ? 'hidden' : 'auto';
    }, [isOpen]);
    const handleBackgroundClick = (event) => {
        if (event.target === event.currentTarget && onClose) {
            onClose();
        }
    };
    return (_jsx(Styled.BackgroundOverlay, Object.assign({ sideBar: isOpen, onClick: handleBackgroundClick }, { children: _jsx(Styled.Container, Object.assign({ sideBar: isOpen }, rest, { children: _jsxs(Stack, Object.assign({ h: "100%", justify: "space-between" }, { children: [_jsxs(Styled.Content, { children: [_jsx(Text, Object.assign({ fontColor: navItemActive === '/panel' ? 'pink500' : 'white100', onClick: onClickPanel, "data-testid": "mobile-panel-link" }, { children: t('components.header.link.panel') })), _jsx(Text, Object.assign({ fontColor: navItemActive === '/campaign' ? 'pink500' : 'white100', onClick: onClickCampaign, "data-testid": "mobile-campaign-link" }, { children: t('components.header.link.campaign') })), _jsx(Text, Object.assign({ fontColor: navItemActive === '/history' ? 'pink500' : 'white100', onClick: onClickHistory, "data-testid": "mobile-history-link" }, { children: t('components.header.link.history') })), _jsx(Text, Object.assign({ fontColor: navItemActive === '/statement' ? 'pink500' : 'white100', onClick: onClickStatement, "data-testid": "mobile-statement-link" }, { children: t('components.header.link.statement') })), _jsx(Text, Object.assign({ fontColor: navItemActive === '/prizes' ? 'pink500' : 'white100', onClick: onClickPrizes, "data-testid": "mobile-prizes-link" }, { children: t('components.header.link.prizes') })), _jsx(Text, Object.assign({ fontColor: navItemActive === '/raffle' ? 'pink500' : 'white100', onClick: onClickRaffle, "data-testid": "mobile-raffle-link" }, { children: t('components.header.link.lotteries') }))] }), _jsxs(Stack, Object.assign({ p: "40px", gap: "20px" }, { children: [_jsx(Text, Object.assign({ weight: "400", fontColor: "white100", fontSize: "18px", lineHeight: "28px", "data-testid": "mobile-my-profile-link", onClick: onClickMyProfile }, { children: t('components.header.link.profile') })), _jsx(Text, Object.assign({ weight: "400", fontColor: "white100", fontSize: "18px", lineHeight: "28px", onClick: () => {
                                    if (logout) {
                                        logout();
                                        navigate('/');
                                    }
                                }, "data-testid": "mobile-logout-link" }, { children: t('components.header.link.logout') }))] }))] })) })) })));
};
export default Sidebar;
