import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PrivateRouteContainer } from '~/layouts/containers';
import { useInitialData } from '~/layouts/hooks/useInitialData';
function RouteWrapper({ component: Component, isProtectedLayout = false, }) {
    const { data } = useInitialData();
    const location = useLocation();
    const { t } = useTranslation();
    const pagesTitle = {
        '/': t('pagesTitle.login'),
        '/recover-password': t('pagesTitle.recoverPassword'),
        '/reset-password': t('pagesTitle.resetPassword'),
        '/signup': t('pagesTitle.signup'),
        '/panel': t('pagesTitle.panel'),
        '/campaign': t('pagesTitle.campaign'),
        '/history': t('pagesTitle.history'),
        '/statement': t('pagesTitle.statement'),
        '/prizes': t('pagesTitle.prizes'),
        '/profile': t('pagesTitle.profile'),
        '/convite': t('pagesTitle.inviteFriends'),
        '/raffle': t('pagesTitle.raffle'),
        '/member-get-member': t('pagesTitle.memberGetMember'),
        '/confirm-email': t('pagesTitle.confirmEmail'),
        '/request-new-code': t('pagesTitle.requestNewCode'),
    };
    const club = `${data === null || data === void 0 ? void 0 : data.account_name} | `;
    const title = club
        ? `${club + pagesTitle[location.pathname]}`
        : pagesTitle[location.pathname];
    useEffect(() => {
        document.title = title;
    }, [club, location]);
    if (isProtectedLayout) {
        return (_jsx(PrivateRouteContainer, Object.assign({ redirectTo: "/" }, { children: _jsx(Component, {}) })));
    }
    return _jsx(Component, {});
}
export default RouteWrapper;
